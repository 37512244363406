import * as React from 'react';
import * as Redux from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { Grid, Switch } from '@mui/material';
import {
  getAllModulosAction,
  saveModulosAction,
  setActivoModulosAction,
} from '../ModulosAction';
import * as CONST from '../../../constants/FieldsConst';
import { SU } from '../../../constants/ConfigConst';
import { MODULOS_ENUM, getPluralLabelTypeEntity } from '../../../constants/enumsConst';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { PARAMS_MODULOS } from '../../../store/Types';
import LoadingComponent from '../../../components/Loading';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import { useNotification } from '../../../helpers/notification';

const ListadoModulos = ({ isGlobal, isPanel = false }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const modulosStore = Redux.useSelector((state) => state.modulos);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const entity = entidadesStore.activo;
  const isClub = entity && entity.entityType.id === 2;

  const setCellHeaderProps = () => {
    return {
      style: {
        width: '110px',
        textAlign: 'center',
      },
    };
  };

  const setCellProps = (value) => {
    return {
      style: {
        textAlign: 'center',
      },
    };
  };

  const defaultColumns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.NAME,
      label: 'Módulos',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => _handleInfoClick(tableMeta.rowData[0])}
            >
              {['Codes'].includes(value) && entity ? getPluralLabelTypeEntity(entity.entityType) : MODULOS_ENUM[value]}
            </NameComponent>
          );
        },
      },
    },
  ]

  const panelColumns = [
    ...defaultColumns,
    ...(loginStore.rol === SU ? [{
      name: CONST.ACTIVE,
      label: 'Activo',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  [CONST.ACTIVE]: e.target.checked,
                })
              }
            />
          );
        },
      },
    }] : []),
    {
      name: CONST.MANAGER,
      label: 'Manager',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  [CONST.MANAGER]: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
    {
      name: 'read',
      label: 'Leer',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  read: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
    {
      name: 'create',
      label: 'Crear',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  create: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
    {
      name: 'update',
      label: 'Actualizar',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  update: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
    {
      name: 'delete',
      label: 'Eliminar',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  delete: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
  ]

  const appColumns = [
    ...defaultColumns,
    ...(loginStore.rol === SU ? [{
      name: CONST.ACTIVE,
      label: 'Activo',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  [CONST.ACTIVE]: e.target.checked,
                })
              }
            />
          );
        },
      },
    }] : []),
    {
      name: CONST.HIDDEN,
      label: 'Ocultar',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (isPanel) return '--';
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  [CONST.HIDDEN]: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
    {
      name: CONST.CLIENT,
      label: 'Cliente',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (isPanel) return '--';
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  [CONST.CLIENT]: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
    {
      name: CONST.OWNER,
      label: 'Propietario Admin',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (isPanel) return '--';
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  [CONST.OWNER]: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
    {
      name: CONST.FAMILY,
      label: isClub ? 'Familiares' : 'Propietario',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (isPanel) return '--';
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  [CONST.FAMILY]: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
    {
      name: CONST.OTHER,
      label: 'Familiar',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (isPanel) return '--';
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  [CONST.OTHER]: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
    {
      name: CONST.SERVICE,
      label: 'Trabajador doméstico',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (isPanel) return '--';
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  [CONST.SERVICE]: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
    {
      name: CONST.DRIVER,
      label: 'Chofer',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (isPanel) return '--';
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  [CONST.DRIVER]: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
    {
      name: CONST.NANNY,
      label: 'Niñeras',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps,
        setCellProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (isPanel) return '--';
          return (
            <Switch
              checked={value}
              onChange={(e) =>
                _handleSaveClick(tableMeta.rowData[0], {
                  [CONST.NANNY]: e.target.checked,
                })
              }
            />
          );
        },
      },
    },
  ]

  const [columns, setColumns] = React.useState(isPanel ? panelColumns : appColumns);

  const su = loginStore.rol === SU;
  const params = {
    ...modulosStore.params,
    entityId:
      entidadesStore.activo && !isGlobal ? entidadesStore.activo.id : null,
  };

  React.useEffect(() => {
    dispatch({
      type: PARAMS_MODULOS,
      payload: params,
    });
    getAllModulosAction(dispatch, params);

    // if (su) {
    //   const index = 2;
    //   setColumns([
    //     ...columns.slice(0, index),
    //     {
    //       name: CONST.ACTIVE,
    //       label: 'Activo',
    //       options: {
    //         filter: true,
    //         sort: false,
    //         setCellHeaderProps,
    //         setCellProps,
    //         customBodyRender: (value, tableMeta, updateValue) => {
    //           return (
    //             <Switch
    //               checked={value}
    //               onChange={(e) =>
    //                 _handleSaveClick(tableMeta.rowData[0], {
    //                   [CONST.ACTIVE]: e.target.checked,
    //                 })
    //               }
    //             />
    //           );
    //         },
    //       },
    //     },
    //     ...columns.slice(index),
    //   ]);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRows([]);
    modulosStore.all.forEach((modulo) => {
      if (modulo.isPanel === !!isPanel) {
        setRows((old) => [...old, modulo]);
      }
    });
  }, [modulosStore.all]);

  const _handleInfoClick = async (id) => {
    const modulo = modulosStore.all.find((el) => el.id === id);
    await setActivoModulosAction(dispatch, modulo);
  };

  const _handleSaveClick = async (id, data) => {
    setLoadingDelete(true);
    const params = modulosStore.params;
    if (!isGlobal) {
      params.entityId = entidadesStore.activo.id;
    } else {
      params.entityId = null;
    }

    await saveModulosAction(
      { dispatch, addNotification },
      isGlobal,
      id,
      data,
      modulosStore.params
    );
    setLoadingDelete(false);
  };

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },

    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={modulosStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

export default ListadoModulos;
