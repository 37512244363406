import * as React from 'react';
import * as Redux from 'react-redux';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import * as CONST from '../../../constants/FieldsConst';
import Box from '@mui/material/Box';
import { saveTiposInvitacionAction } from '../TiposInvitacionAction';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import LoadingComponent from '../../../components/Loading';
import { useNotification } from '../../../helpers/notification';

const FormTiposInvitacion = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposInvitacionStore = Redux.useSelector(
    (state) => state.tiposInvitacion
  );

  const activo = tiposInvitacionStore.activo;

  const initData = {
    [CONST.NAME]: '',
    [CONST.ACTIVE]: true,
    [CONST.MAX_INVITATIONS]: 1000,
    [CONST.DAYS_MAX_INVITATIONS]: 30,
    [CONST.MAX_INVITATIONS_PER_GUEST]: 4,
    [CONST.DAYS_MAX_INVITATIONS_PER_GUEST]: 30,
  };

  const [documento, setDocumento] = React.useState(initData);
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    if (activo === null) return;
    let data = {};
    Object.keys(documento).map(
      (key) => (data[key] = tiposInvitacionStore.activo[key])
    );
    setTimeout(() => {
      setDocumento(data);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  const _handleSetDataField = (key, value) =>
    setDocumento({ ...documento, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).map((key) => {
      const val = documento[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      return va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = await _validDataForm();

    if (valid) {
      setLoadingSave(true);
      let res = await saveTiposInvitacionAction(
        { dispatch, addNotification },
        entidadesStore.activo.id,
        activo ? activo.id : null,
        {
          ...documento,
          [CONST.ENTITY_ID]: entidadesStore.activo.id,
        }
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };

  return (
    <Box component="form" sx={{}}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            id={CONST.NAME}
            required
            label="Nombre"
            value={documento.name}
            onChange={(e) => _handleSetDataField(CONST.NAME, e.target.value)}
            helperText={dataError.name ? validFielddHelper(1) : null}
            error={dataError.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id={`label-${CONST.ACTIVE}`}>Estatus</InputLabel>
            <Select
              labelId={`label-${CONST.ACTIVE}`}
              size="small"
              value={documento.active}
              label="Estatus"
              onChange={(e) =>
                _handleSetDataField(CONST.ACTIVE, e.target.value)
              }
              id={CONST.ACTIVE}
            >
              <MenuItem value={true}>Activo</MenuItem>
              <MenuItem value={false}>Bloqueado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            type="number"
            required
            label="Máximo de invitaciones"
            value={documento[CONST.MAX_INVITATIONS]}
            onChange={(e) =>
              _handleSetDataField(CONST.MAX_INVITATIONS, Number(e.target.value))
            }
            id={CONST.MAX_INVITATIONS}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            type="number"
            required
            label=" Días máx. invitaciones"
            value={documento[CONST.DAYS_MAX_INVITATIONS]}
            onChange={(e) =>
              _handleSetDataField(
                CONST.DAYS_MAX_INVITATIONS,
                Number(e.target.value)
              )
            }
            id={CONST.DAYS_MAX_INVITATIONS}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            type="number"
            required
            label="Max. invitaciones por invitado"
            value={documento[CONST.MAX_INVITATIONS_PER_GUEST]}
            onChange={(e) =>
              _handleSetDataField(
                CONST.MAX_INVITATIONS_PER_GUEST,
                Number(e.target.value)
              )
            }
            id={CONST.MAX_INVITATIONS_PER_GUEST}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            type="number"
            required
            label="Días máx. invitaciones por invitado  "
            value={documento[CONST.DAYS_MAX_INVITATIONS_PER_GUEST]}
            onChange={(e) =>
              _handleSetDataField(
                CONST.DAYS_MAX_INVITATIONS_PER_GUEST,
                Number(e.target.value)
              )
            }
            id={CONST.DAYS_MAX_INVITATIONS_PER_GUEST}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} align="center" sx={{ m: 2 }}>
          <BotonNuevoComponent
            click={_handleSubmit}
            text="GUARDAR"
            disabled={loadingSave}
            morado
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormTiposInvitacion;
