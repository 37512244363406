import {
  Avatar,
  Card,
  CardContent,
  TextField,
  CircularProgress,
  Button,
} from '@mui/material';
import { Paper } from '@mui/material';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import CopyrightTemplate from '../../../components/template/Copyright';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import { baseApi } from '../../../services/Apis';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import * as CONST from '../../../constants/FieldsConst';
import PhoneNumberField from '../../../components/fields/PhoneNumberField';
import { CorreoField } from '../../usuarios/components/FieldsUsuarios';
import {
  ButtonFile,
  GenerosField,
  TiposDocumentosField,
} from '../../../components';
import { useNotification } from '../../../helpers/notification';

var ExpRegSoloNumeros = '^[0-9]+$';
var ExpRegSoloNumerosPassport = '^[0-9a-zA-Z]+$';
var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

const EventConfirmationParticipant = () => {
  const { uid } = useParams();

  const { addNotification } = useNotification();
  const [avatar, setAvatar] = React.useState(null);
  const acceptOnlyImages = ['image/jpeg', 'image/png', 'image/jpg'];

  const [data, setData] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    identificationNumber: '',
    phone: '',
    typeDocument: 'V',
    gender: 'male',
    avatar: null,
  });

  const [isEventInvalid, setIsEventInvalid] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [confirmationComplete, setConfirmationComplete] = React.useState(false);
  const [dataError, setDataRequired] = React.useState({
    [CONST.FIRST_NAME]: false,
    [CONST.LAST_NAME]: false,
    [CONST.PHONE]: false,
    [CONST.EMAIL]: false,
    [CONST.IDENTIFICATION_NUMBER]: false,
  });

  const [event, setEvent] = React.useState({
    name: '',
    date: '',
    description: '',
    area: {
      name: '',
    },
    userCode: {
      user: {
        profile: {
          firstName: '',
          lastName: '',
        },
      },
    },
  });

  const [loading, setLoading] = React.useState(false);

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).map((key) => {
      const val = data[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      return va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  React.useEffect(() => {
    axios
      .get(`${baseApi}/events/uid/${uid}`)
      .then((res) => {
        setEvent(res.data);
      })
      .catch((err) => {
        setIsEventInvalid(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const valid = _validDataForm();

    if (!valid) {
      return;
    }

    let _data = data;
    const headers = {};

    if (avatar) {
      _data.avatar = avatar;
      _data = new FormData();
      Object.keys(data).forEach((key) => {
        _data.append(key, data[key]);
      });
      headers['Content-Type'] = 'multipart/form-data';
    }

    setLoading(true);
    await axios
      .post(`${baseApi}/events/${event.id}/confirm-participant`, _data, headers)
      .then((res) => {
        setConfirmationComplete(true);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          addNotification(err.response.data.message.join(', '), {
            warning: true,
          });
        }
      });
    setLoading(false);
  };

  return (
    <center>
      <Box
        sx={{
          width: {
            xs: '90%',
            md: '40%',
          },
        }}
      >
        <Avatar
          alt="On Pass"
          src="/img/logoon.png"
          sx={{
            width: {
              xs: 100,
              md: 150,
            },
            height: {
              xs: 100,
              md: 150,
            },
            padding: '20px',
          }}
        />
        <Typography component="h1" variant="h3" sx={{ fontWeight: '700' }}>
          ON PASS
        </Typography>
        {!isEventInvalid && (
          <Card sx={{ maxWidth: 345, marginTop: '15px' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {event.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {' '}
                Fecha: {moment(event.date).format('DD-MM-YYYY hh:mm A')}{' '}
              </Typography>
              {event.area && (
                <Typography variant="body2" color="text.secondary">
                  {' '}
                  Area: {event.area.name}{' '}
                </Typography>
              )}
              {event.description && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginTop: '15px' }}
                >
                  {' '}
                  {event.description}{' '}
                </Typography>
              )}
            </CardContent>
          </Card>
        )}
        <Paper elevation={5} sx={{ p: 3, marginTop: '15px' }}>
          {!isEventInvalid ? (
            !confirmationComplete ? (
              <Grid>
                <Typography sx={{ marginBottom: '15px' }}>
                  Por favor completa los siguientes campos:
                </Typography>
                <Grid item xs={12} md={3}>
                  <ButtonFile
                    id={CONST.AVATAR}
                    onFile={(avatar) => setAvatar(avatar)}
                    onDelete={() => setAvatar(null)}
                    text="Subir Foto"
                    sxc={{ marginTop: '5px' }}
                    name={''}
                    file={avatar}
                    size={100}
                    camera={true}
                    src={null}
                    accept={acceptOnlyImages}
                  />
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginBottom: '25px' }}>
                  <TextField
                    fullWidth
                    size="small"
                    id={CONST.FIRST_NAME}
                    required
                    disabled={loading}
                    label="Nombre"
                    value={data.firstName}
                    onChange={(e) => {
                      setData({ ...data, firstName: e.target.value });
                    }}
                    helperText={dataError.firstName ? validFielddHelper(1) : null}
                    error={dataError.firstName}
                  />
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginBottom: '25px' }}>
                  <TextField
                    fullWidth
                    size="small"
                    id={CONST.LAST_NAME}
                    required
                    disabled={loading}
                    label="Apellido"
                    value={data.lastName}
                    onChange={(e) => {
                      setData({ ...data, lastName: e.target.value });
                    }}
                    helperText={dataError.lastName ? validFielddHelper(1) : null}
                    error={dataError.lastName}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ marginBottom: '25px' }}>
                  <CorreoField
                    v={data.email}
                    on={(e) => {
                      let text = e.target.value;
                      setData({ ...data, email: text });
                      if (validEmail.test(text)) {
                        setErrorEmail(false);
                        return;
                      }
                      setErrorEmail(true);
                    }}
                    dis={loading}
                    autoComplete="off"
                    errorEmail={errorEmail}
                    e={errorEmail || dataError.email}
                    required={false}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ marginBottom: '25px', display: 'flex' }}
                >
                  <Grid item sx={{ width: '120px' }}>
                    <TiposDocumentosField
                      disabled={loading}
                      value={data.typeDocument}
                      onChange={(e, v) =>
                        setData({ ...data, typeDocument: e.target.value })
                      }
                      error={dataError.typeDocument}
                    />
                  </Grid>
                  <Grid item sx={{ flex: 1, marginLeft: '10px' }}>
                    <TextField
                      fullWidth
                      disabled={loading}
                      size="small"
                      id={CONST.IDENTIFICATION_NUMBER}
                      required
                      label="Número de identificación"
                      value={data.identificationNumber}
                      onChange={(e) => {
                        let text = e.target.value;

                        if (data.typeDocument.toString() === 'V') {
                          text = text.replace(/[^0-9]/g, '');

                          if (text.length === 9) return;
                        }

                        let v = false;
                        if (data.typeDocument.toString() === 'P') {
                          if (text.length === 20) return;
                          v = text.match(ExpRegSoloNumerosPassport) != null;
                        } else {
                          v = text.match(ExpRegSoloNumeros) != null;
                        }

                        if (text === '') {
                          return;
                        }

                        if (!v) {
                          text = text.slice(0, -1);
                        }

                        setData({ ...data, identificationNumber: text });
                      }}
                      helperText={
                        dataError.identificationNumber
                          ? validFielddHelper(1)
                          : null
                      }
                      error={dataError.identificationNumber}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} sx={{ marginBottom: '25px' }}>
                  <PhoneNumberField
                    disabled={loading}
                    value={data.phone}
                    onChange={(value) => setData({ ...data, phone: value })}
                    error={dataError.phone}
                    fieldName={CONST.PHONE}
                    required={true}
                    dataError={dataError}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ marginBottom: '25px', textAlign: 'left !important' }}
                >
                  <GenerosField
                    disabled={loading}
                    value={data.gender}
                    onChange={(e, v) =>
                      setData({ ...data, gender: e.target.value })
                    }
                    error={dataError.gender}
                  />
                </Grid>

                <Grid item md={12} align="center" sx={{ m: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Enviar'}
                  </Button>
                  {/* <BotonNuevoComponent
                    click={handleSubmit}
                    text="GUARDAR"
                    disabled={loading}
                    morado
                  /> */}
                </Grid>
              </Grid>
            ) : (
              <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
                <CheckIcon sx={{ fontSize: 100, color: 'green' }} />
                <Typography variant="h6" component="div">
                  ¡Se ha confirmado tu asistencia!
                </Typography>

                <Typography variant="h6" component="div">
                  Se ha enviado un correo con la información del evento
                </Typography>
              </Typography>
            )
          ) : (
            <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
              El evento ha expirado o no es válido
            </Typography>
          )}
        </Paper>
        <CopyrightTemplate sx={{ mt: 5 }} />
      </Box>
    </center>
  );
};

export default EventConfirmationParticipant;
