import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton } from '@mui/material';
import * as React from 'react';
import CameraButton from './camera';

export const acceptOnlyImages = 'image/png, image/jpeg, image/jpg';

const ButtonFile = ({
  id,
  text,
  src,
  name,
  file,
  sxc,
  st,
  onFile,
  onDelete,
  size,
  isFile,
  accept = '',
  camera = false,
  disabled = false,
  readOnly = false,
  avatar = false
}) => {
  const [openCamera, setOpenCamera] = React.useState(false);
  return (
    <div style={{...sxc, position: 'relative'}}>
      {openCamera && (
        <CameraButton
          onFile={(file) => {
            onFile(file);
            setOpenCamera(false);
          }}
          onClose={() => setOpenCamera(false)}
        />
      )}
      {!disabled && !readOnly && (
        <>
          <div>

            <label label htmlFor={id} style={avatar ? {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: 'hidden',
                width: '130px',
                height: '130px',
              } : { position: 'relative' }}>
              <input
                id={id}
                name={id}
                style={{ display: 'none' }}
                type="file"
                accept={accept}
                disabled={disabled}
                // accept="*"
                onChange={(e) => onFile(e.target.files[0])}
              />
              <Button className="btn-choose" variant="outlined" component="span" style={avatar ? {
                backgroundColor: 'transparent',
                border: 'none',
                outline: 'none',
              } : { }}>
                {avatar ? '' : text}
                
              </Button>
            </label>
          </div>
          {camera ? (
            <Button
              className="btn-choose"
              variant="outlined"
              component="span"
              style={{ marginLeft: 10 }}
              onClick={() => setOpenCamera(true)}
            >
              <CameraAltIcon />
            </Button>
          ) : null}
        </>
      )}
      <div
        className="file-name"
        sx={{
          width: size ?? '80%',
        }}
      >
        {!avatar && file ? (
          <IconButton aria-label="delete" onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onDelete();
          }} color="error">
            <DeleteIcon />
          </IconButton>
        ) : null}
        {name ?? (!avatar && file ? file.name : null)}
      </div>
      {isFile ? null : (
        <div style={avatar ? {
          width: '130px',
          height: '130px',
          borderRadius: '50%',
          overflow: 'hidden',
        }: {}}>
          {!disabled && avatar && file ? (
            <IconButton aria-label="delete" onClick={onDelete} color="error" style={{
              position: 'absolute',
              bottom: -2,
              right: 0
            }}>
              <DeleteIcon />
            </IconButton>
          ) : null}
          <img
            style={{
              width: size ?? '80%',
              ...st,
            }}
            src={src ?? (file ? file?.includes?.('http') ? file :  URL.createObjectURL(file) : (avatar ? require('../assets/images/user-default.png') : null))}
            alt={file ? file.name : avatar ? 'avatar' : src ? src.name : ''}
          />
        </div>
      )}
    </div>
  );
};

export default ButtonFile;
