import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List as ListM,
  Tooltip,
} from '@mui/material';
import { grey, purple } from '@mui/material/colors';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MORADO_COLOR } from '../../../constants/ColorsConst';

const ItemDrawer = ({ item, openDrawer }) => {
  const location = useLocation();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => setOpen(!open);

  const xLink = (i, t, to) => {
    const active = location.pathname.includes(to);
    return (
      <Link
        key={t}
        to={to}
        style={{
          color: 'black',
          textDecoration: 'none',
        }}
      >
        <ListItemButton
          sx={{
            backgroundColor: active ? MORADO_COLOR : 'transparent',
            color: active ? 'white' : 'black',
            borderRadius: '7px',
            '&:hover': {
              backgroundColor: active ? purple[900] : grey[300],
              color: active ? 'white' : 'black',
              fontWeight: 'bold',
            },
            width: '98%',
          }}
          dense={true}
        >
          <ListItemIcon
            sx={{
              color: active ? 'white' : 'black',
              minWidth: '40px',
            }}
          >
            <Tooltip title={t}>{i}</Tooltip>
          </ListItemIcon>
          {openDrawer && <ListItemText primary={t} />}
        </ListItemButton>
      </Link>
    );
  };

  const xCollapse = (i) => {
    const active = location.pathname.includes(i.to);

    return (
      <>
        <ListItemButton
          onClick={handleClick}
          dense={true}
          sx={{
            backgroundColor: active ? '#e6e6e6' : 'transparent',
            borderRadius: '7px',
            '&:hover': {
              backgroundColor: active ? purple[800] : grey[300],
              color: active ? 'white' : 'black',
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: '40px' }}>
            <Tooltip title={i.title}>{i.icon}</Tooltip>
          </ListItemIcon>
          {openDrawer && <ListItemText primary={i.title} />}
          {openDrawer && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ListM component="div" disablePadding sx={{ ml: openDrawer ? 1 : 0 }}>
            {i.vistas.map((route) => {
              return route ? xLink(route.icon, route.title, route.to) : null;
            })}
          </ListM>
        </Collapse>
      </>
    );
  };

  return item.vistas && item.vistas.length > 0
    ? xCollapse(item)
    : xLink(item.icon, item.title, item.to);
};

export default ItemDrawer;
