import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../components';
import * as CONST from '../../constants/FieldsConst';
import { getLabelTypeEntity } from '../../constants/enumsConst';
import { dateformat, isSwitch } from '../../helpers';
import { PARAMS_RESERVAS } from '../../store/Types';
import FechasPersonalizada from '../accesos/components/FechasPersonalizada';
import { getAllAreasAction } from '../areas/AreasAction';
import { getSearchCodesCodigosAction } from '../codigos/CodigosAction';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import BotonFechas from '../invitados/components/BotonFechas';
import SwitchFechaComponent from '../invitados/components/SwitchFecha';
import { checkModuleAccess } from '../modulos/modules.helpers';
import { getSearchUsersAction } from '../usuarios/UsuariosAction';
import { getAllReservasAction } from './ReservasAction';
import FormReserva from './components/FormReservas';
import ListadoReservas from './components/ListadoReservas';

const ReservasModule = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const areasStore = Redux.useSelector((state) => state.areas);
  const reservasStore = Redux.useSelector((state) => state.reservas);
  const codigosStore = Redux.useSelector((state) => state.codigos);
  const usuariosStore = Redux.useSelector((state) => state.usuarios);

  const loginStore = Redux.useSelector((state) => state.login);
  const accessName = 'reservation';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [dataCodes, setDataCodes] = React.useState([]);
  const [dataUsers, setDataUsers] = React.useState([]);
  const [loadingSelectCode, setLoadingDataCode] = React.useState(false);
  const [loadingSelectUser, setLoadingDataUser] = React.useState(false);

  const [isRango, setIsRango] = React.useState(false);
  const [isFuturo, setIsFuturo] = React.useState(true);
  const [date, setDate] = React.useState(
    dayjs(dateformat(null, { full: true })).toDate()
  );
  const [dateTo, setDateTo] = React.useState(
    dayjs(dateformat(null, { full: true }))
      .add(30, 'd')
      .toDate()
  );
  const [openModalForm, setOpenModalForm] = React.useState(false);

  const timezone = entidadesStore.activo.country.timezone;
  const utcOffset = entidadesStore.activo.country.utcOffset || 'UTC-04:00';

  async function fetchData() {
    if (reservasStore.params.areaId || reservasStore.params.userId) {
      await getAllReservasAction(dispatch, {
        ...reservasStore.params,
        date: dayjs(reservasStore.params.date).utcOffset(utcOffset).toDate(),
        dateTo: dayjs(reservasStore.params.dateTo)
          .utcOffset(utcOffset)
          .toDate(),
      });
    }
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const { data: areas } = await getAllAreasAction(dispatch, {
        entityId: entidadesStore.activo.id,
      });
      const payload = {
        ...reservasStore.params,
        entityId: entidadesStore.activo.id,
        date: dayjs(date).utcOffset(utcOffset).toDate(),
        dateTo: dayjs(dateTo).utcOffset(utcOffset).toDate(),
        areaId: areas[0]?.id || null,
        userId: null,
        codeId: null,
      };

      dispatch({
        type: PARAMS_RESERVAS,
        payload,
      });
    };
    fetchData();
  }, []);

  const isPublic = entidadesStore.activo.isPublic;

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservasStore.params]);

  React.useEffect(() => {
    setDataCodes([]);
    if (codigosStore.searchCodes.length > 0) {
      codigosStore.searchCodes.map((code) => {
        return setDataCodes((old) => [
          ...old,
          {
            [CONST.ID]: code.id,
            [CONST.CODE]: code.code,
          },
        ]);
      });
    }
  }, [codigosStore.searchCodes]);

  React.useEffect(() => {
    setDataUsers([]);
    if (usuariosStore.searchUsers && usuariosStore.searchUsers.length > 0) {
      usuariosStore.searchUsers.map((user) => {
        return setDataUsers((old) => [
          ...old,
          {
            [CONST.ID]: user.id,
            [CONST.NAME]: `${user.profile.firstName} ${user.profile.lastName}`,
          },
        ]);
      });
    }
  }, [usuariosStore.searchUsers]);

  React.useEffect(() => {
    dispatch({
      type: PARAMS_RESERVAS,
      payload: {
        ...reservasStore.params,
        entityId: entidadesStore.activo.id,
        date,
        dateTo,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, dateTo]);

  var delayTimer;
  function doSearch(text) {
    clearTimeout(delayTimer);
    setLoadingDataCode(true);
    delayTimer = setTimeout(async () => {
      await getSearchCodesCodigosAction(
        dispatch,
        entidadesStore.activo.id,
        text
      );
      setLoadingDataCode(false);
    }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  }

  var delayTimerUser;
  function doSearchUser(text) {
    clearTimeout(delayTimerUser);
    setLoadingDataUser(true);
    delayTimerUser = setTimeout(async () => {
      await getSearchUsersAction(dispatch, entidadesStore.activo.id, text);
      setLoadingDataUser(false);
    }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  }

  let selectCodeComponent;

  if (!isPublic) {
    selectCodeComponent = (
      <Autocomplete
        freeSolo
        id={'selectCode'}
        disableClearable
        options={dataCodes.map((option) => option.code)}
        size="small"
        loading={loadingSelectCode}
        onChange={(event, newValue) => {
          const item = codigosStore.searchCodes.find(
            (a) => a.code === newValue
          );
          dispatch({
            type: PARAMS_RESERVAS,
            payload: {
              ...reservasStore.params,
              codeId: item.id,
            },
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => {
              if (e) {
                doSearch(e.target.value);
              }
              delete reservasStore.params.codeId;
              dispatch({
                type: PARAMS_RESERVAS,
                payload: {
                  ...reservasStore.params,
                },
              });
            }}
            label={`Buscar por ${getLabelTypeEntity(
              entidadesStore.entityType.name
            )}`}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <React.Fragment>
                  {loadingSelectCode ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  } else {
    selectCodeComponent = (
      <Autocomplete
        freeSolo
        id={'selectCode'}
        disableClearable
        options={dataUsers.map((option) => option.name)}
        size="small"
        loading={loadingSelectUser}
        onChange={(event, newValue) => {
          const item = usuariosStore.searchUsers.find(
            (a) => `${a.profile.firstName} ${a.profile.lastName}` === newValue
          );
          dispatch({
            type: PARAMS_RESERVAS,
            payload: {
              ...reservasStore.params,
              userId: item.id,
            },
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => {
              if (e) {
                doSearchUser(e.target.value);
              }
              delete reservasStore.params.userId;
              dispatch({
                type: PARAMS_RESERVAS,
                payload: {
                  ...reservasStore.params,
                },
              });
            }}
            label={`Buscar por usuario`}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <React.Fragment>
                  {loadingSelectUser ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }

  const selectAreasComponent = (
    <FormControl
      size="small"
      sx={{ width: '100%' }}
      error={reservasStore?.params?.areaId === undefined}
    >
      <InputLabel id="selectAreas">Áreas *</InputLabel>
      <Select
        value={reservasStore.params.areaId}
        labelId="selectAreas"
        label="Areas *"
        size="small"
        onChange={(e) => {
          dispatch({
            type: PARAMS_RESERVAS,
            payload: {
              ...reservasStore.params,
              areaId: e.target.value,
            },
          });
        }}
        id="selectAreas"
      >
        {areasStore.all.map((el) => {
          return (
            <MenuItem key={`areas${el.id}`} value={el.id}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const filtercomponent = (
    <Paper elevation={2} sx={{ p: 2, width: '100%', display: 'flex' }}>
      <BotonNuevoComponent
        click={async () => {
          // await setActivoCodigosAction(dispatch, null);
          setOpenModalForm(true);
        }}
        azul
        mr={2}
      />
      <BotonNuevoComponent
        click={async () => {
          await fetchData();
        }}
        white
        mr={2}
        disabled={reservasStore.loadingGetAll}
        text={reservasStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'}
        sx={{ padding: '0px 15px' }}
      />

      <Grid container spacing={1}>
        <Grid item md={isRango ? 2 : 3}>
          {selectCodeComponent}
        </Grid>

        <Grid item md={isRango ? 2 : 3}>
          {selectAreasComponent}
        </Grid>

        <Grid item md={isRango ? 3 : 6}>
          <Stack direction="row" alignItems="center">
            <BotonFechas
              futuro={isFuturo}
              selectedItemIndex={4}
              dateRange={(start, end) => {
                setDate(dateformat(start, { full: true }));
                setDateTo(dateformat(end, { full: true }));
              }}
              setIsRango={(v) => setIsRango(v)}
            />
            {isSwitch(isFuturo, date, dateTo) || isRango ? null : (
              <Box sx={{ ml: 2, mt: 1 }}>
                <SwitchFechaComponent
                  value={isFuturo}
                  onClickLeft={() => setIsFuturo(false)}
                  onClickRigth={() => setIsFuturo(true)}
                  onChange={(e) => setIsFuturo(e.target.checked)}
                />
              </Box>
            )}
          </Stack>
        </Grid>
        {isRango && (
          <FechasPersonalizada
            startDate={date}
            mdStart={2}
            mdEnd={2}
            endDate={dateTo}
            onStart={(value) => {
              if (!value) return;
              setDate(value);
            }}
            onEnd={(value) => {
              if (!value) return;
              setDateTo(value);
            }}
          />
        )}
      </Grid>
    </Paper>
  );

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        {filtercomponent}
      </Grid>
      <Grid item md={12}>
        <ListadoReservas date={date} dateTo={dateTo} />
      </Grid>
      <Modal
        id="modalFormReserva"
        title={'Nueva Reserva'}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        <FormReserva
          setOpenModalForm={(val) => setOpenModalForm(val)}
          params={reservasStore.params}
          onRefresh={() => fetchData()}
        />
      </Modal>
    </Grid>
  );
};

export default ReservasModule;
