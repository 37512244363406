import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import * as Redux from 'react-redux';
import LoadingComponent from '../../../components/Loading';
import PhoneNumberField from '../../../components/fields/PhoneNumberField';
import * as CONST from '../../../constants/FieldsConst';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../helpers/notification';
import { getAllBancosAction } from '../../bancos/BancosAction';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { CorreoField } from '../../usuarios/components/FieldsUsuarios';
import { saveEntidadBancosAction } from '../EntidadBancosAction';

const FormEntidadBancos = () => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const bancosStore = Redux.useSelector((state) => state.bancos);
  const entidadBancosStore = Redux.useSelector((state) => state.entidadBancos);

  const activo = entidadBancosStore.activo;

  const dataDefault = {
    [CONST.ACCOUNT_NUMBER]: '',
    [CONST.BANK_DOCUMENT]: '',
    [CONST.ACCOUNT_TYPE]: '',
    [CONST.EMAIL]: '',
    [CONST.PHONE]: '',
    [CONST.BANK_ID]: '',
  };
  const [entidadBanco, setEntidadBanco] = React.useState(dataDefault);
  const [dataError, setDataError] = React.useState({
    [CONST.ACCOUNT_NUMBER]: false,
    [CONST.BANK_DOCUMENT]: false,
    [CONST.ACCOUNT_TYPE]: false,
    [CONST.EMAIL]: false,
    [CONST.PHONE]: false,
    [CONST.BANK_ID]: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      if (bancosStore.all && bancosStore.all.length === 0) {
        await getAllBancosAction(dispatch, null);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (activo === null) {
      setEntidadBanco(dataDefault);
      return;
    }
    let data = {};
    Object.keys(entidadBanco).map(
      (key) => (data[key] = entidadBancosStore.activo[key])
    );
    data.bankId = activo?.bank.id;
    setTimeout(() => {
      setEntidadBanco(data);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  const _handleSetDataField = (key, value) =>
    setEntidadBanco({ ...entidadBanco, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = entidadBanco[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      va.push(valid);
    });
    setDataError(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = _validDataForm();

    if (valid) {
      const data = {
        ...entidadBanco,
        [CONST.ENTITY_ID]: entidadesStore.activo.id,
      };
      setLoadingSave(true);
      await saveEntidadBancosAction(
        { dispatch, addNotification },
        activo ? activo.id : null,
        data,
        entidadBancosStore.params
      );
      setLoadingSave(false);
    }
  };

  return (
    <Box component="form" sx={{ m: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {activo ? 'Editar' : 'Nuevo'} Banco
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl size="small" required fullWidth error={dataError.bankId}>
            <InputLabel id="select-bancos">Banco</InputLabel>
            <Select
              label="Banco"
              labelId="select-bancos"
              id={CONST.BANK_ID}
              value={entidadBanco.bankId}
              required
              onChange={(e) => {
                _handleSetDataField(CONST.BANK_ID, e.target.value);
              }}
            >
              {bancosStore.all.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  ({item.code}) - {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            id={CONST.ACCOUNT_NUMBER}
            required
            label="Número de cuenta"
            value={entidadBanco.accountNumber}
            onChange={(e) =>
              _handleSetDataField(CONST.ACCOUNT_NUMBER, e.target.value)
            }
            helperText={dataError.accountNumber ? validFielddHelper(1) : null}
            error={dataError.accountNumber}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            id={CONST.BANK_DOCUMENT}
            required
            label="Documento"
            value={entidadBanco.bankDocument}
            onChange={(e) =>
              _handleSetDataField(CONST.BANK_DOCUMENT, e.target.value)
            }
            helperText={dataError.bankDocument ? validFielddHelper(1) : null}
            error={dataError.bankDocument}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl size="small" required fullWidth>
            <InputLabel id={`label-${CONST.ACCOUNT_TYPE}`}>
              Tipo de cuenta
            </InputLabel>
            <Select
              labelId={`label-${CONST.ACCOUNT_TYPE}`}
              value={entidadBanco.accountType}
              label="Tipo de cuenta"
              onChange={(e) =>
                _handleSetDataField(CONST.ACCOUNT_TYPE, e.target.value)
              }
              id={CONST.ACCOUNT_TYPE}
            >
              <MenuItem value="CC">Corriente</MenuItem>
              <MenuItem value="CA">Ahorro</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <CorreoField
            v={entidadBanco.email}
            on={(e) => {
              var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

              let text = e.target.value;
              _handleSetDataField(CONST.EMAIL, text);
              if (validEmail.test(text)) {
                setDataError({ ...dataError, email: false });
                return;
              }
              setDataError({ ...dataError, email: true });
            }}
            autoComplete="off"
            errorEmail={dataError.email}
            e={dataError.email}
            required={false}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <PhoneNumberField
            value={entidadBanco.phone}
            onChange={(value) => _handleSetDataField(CONST.PHONE, value)}
            error={dataError.phone}
            fieldName={CONST.PHONE}
            required={true}
            dataError={dataError}
          />
        </Grid>
        <Grid item md={12} sx={{ m: 2 }}>
          <Stack direction="row" justifyContent="space-evenly" spacing={1}>
            <BotonNuevoComponent
              click={_handleSubmit}
              text="GUARDAR"
              disabled={loadingSave}
              morado
              mr={2}
            />
            {/* <BotonNuevoComponent
              click={() => {
                setModalExcel(true);
              }}
              text="SUBIR ARCHIVO"
              disabled={loadingSave}
              azul
              mr={2}
            /> */}
          </Stack>
          {/* <center>
            <BotonNuevoComponent
              click={() => {
                window.location.href =
                  process.env.REACT_APP_API_URL +
                  '/bulk-schemas/payment-schema.xlsx';
              }}
              text="BAJAR FORMATO DE EXCEL"
              disabled={loadingSave}
              cyan
              mt={1}
            />
          </center> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormEntidadBancos;
