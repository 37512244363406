import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../components';
import { SU } from '../../constants/ConfigConst';
import {
  getAllSubAreasAction,
  setActivoAreasAction,
  setActivoSubAreasAction,
} from '../../store/Actions';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { isManagerUserCode } from '../modulos/modules.helpers';
import FormSubAreas from './components/FormSubAreas';
import ListadoSubAreas from './components/ListadoSubAreas';

const SubAreasView = ({ isBlock = false }) => {
  const dispatch = Redux.useDispatch();
  const areasStore = Redux.useSelector((state) => state.areas);
  const subareasStore = Redux.useSelector((state) => state.subareas);
  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);

  const [openModalForm, setOpenModalForm] = React.useState(false);
  const [openModalAreas, setOpenModalAreas] = React.useState(false);

  React.useEffect(() => {
    setOpenModalForm(false);
  }, [areasStore.activo]);

  return (
    <Grid container spacing={1}>
      {areasStore.activo && (
        <>
          <Grid item md={12} sx={{ mt: 1 }}>
            {!isManager && (
              <BotonNuevoComponent
                click={async () => {
                  await setActivoSubAreasAction(dispatch, null);
                  setOpenModalForm(true);
                }}
                azul
                ml={1}
              />
            )}
            {subareasStore.all.length > 0 && !isBlock ? (
              <BotonNuevoComponent
                click={async () => {
                  await setActivoSubAreasAction(dispatch, null);
                  await getAllSubAreasAction(dispatch, subareasStore.params);
                }}
                white
                disabled={subareasStore.loadingGetAll}
                text={
                  subareasStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
                }
              />
            ) : null}
            {isBlock ? null : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={async () => {
                  setOpenModalAreas(true);
                }}
              >
                Lista de Áreas
              </Button>
            )}
          </Grid>
          <Grid item md={4} align="right">
            {isBlock ? null : (
              <Typography variant="h6" component="div">
                {areasStore.activo.name}
              </Typography>
            )}
          </Grid>
          <Grid item md={12}>
            <ListadoSubAreas
              setOpenModalForm={(val) => setOpenModalForm(val)}
            />
          </Grid>
          <Modal
            id="modalFormSubAreas"
            title={`${subareasStore.activo ? 'Editar' : 'Nueva'} sub área en ${
              areasStore.activo.name
            }`}
            open={openModalForm}
            onClose={() => setOpenModalForm(!openModalForm)}
          >
            {subareasStore.activo && (
              <Typography variant="h6">
                {subareasStore.activo.nombres} {subareasStore.activo.apellidos}
              </Typography>
            )}
            <FormSubAreas
              activo={subareasStore.activo}
              setOpenModalForm={(val) => setOpenModalForm(val)}
            />
          </Modal>
        </>
      )}
      <Modal
        id="modalSubAreas"
        title={`Áreas (${areasStore.all.length})`}
        open={openModalAreas}
        maxWidth="lg"
        onClose={() => setOpenModalAreas(!openModalAreas)}
      >
        <Grid container spacing={2}>
          {areasStore.all.map((el) => {
            return (
              <Grid key={`area-${el.id}`} item md={3}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardActionArea
                    onClick={async () => {
                      await setActivoAreasAction(dispatch, el);
                      setOpenModalAreas(!openModalAreas);
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={el.imageUrl}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {el.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Modal>
    </Grid>
  );
};

export default SubAreasView;
