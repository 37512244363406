import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const areas = `${baseApi}/areas`;

export const getAllAreasService = async (params) => {
  if (params) params.panel = true;
  const res = await axios.get(areas, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveAreasService = async (id, data) => {
  if (data.image || data.mapImage) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.image;
    delete data.mapImage;
  }

  let res;
  if (id) {
    res = await axios.put(`${areas}/${id}`, data, {
      headers: await token({
        isFormData: !!data.image,
      }),
    });
  } else {
    res = await axios.post(areas, data, {
      headers: await token({
        isFormData: !!data.image,
      }),
    });
  }
  return res.data;
};

export const getOneAreasService = async (id) => {
  const res = await axios.get(`${areas}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteAreasService = async (id) => {
  const res = await axios.delete(`${areas}/${id}`, {
    headers: await token({}),
  });
  return res;
};
