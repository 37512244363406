import { Grid, Paper } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../modulos/modules.helpers';
import {
  getAllEntidadBancosAction,
  setActivoEntidadBancosAction,
} from './EntidadBancosAction';
import FormEntidadBancos from './components/FormEntidadBancos';
import ListadoEntidadBancos from './components/ListadoEntidadBancos';

const EntidadBancosView = () => {
  const dispatch = Redux.useDispatch();
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const loginStore = Redux.useSelector((state) => state.login);
  const entidadBancosStore = Redux.useSelector((state) => state.entidadBancos);

  const accessName = 'entity-banks';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoEntidadBancosAction(dispatch, null);
          }}
          azul
          mr={2}
        />
        {entidadBancosStore.all.length > 0 && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoEntidadBancosAction(dispatch, null);
              await getAllEntidadBancosAction(dispatch, {
                entityId: entidadesStore.activo.id,
                ...entidadBancosStore.params,
              });
            }}
            white
            disabled={entidadBancosStore.loadingGetAll}
            text={
              entidadBancosStore.loadingGetAll
                ? 'Actualizando...'
                : 'Actualizar'
            }
          />
        )}
      </Grid>
      <Grid item md={4}>
        <Paper elevation={4}>
          <FormEntidadBancos />
        </Paper>
      </Grid>
      <Grid item md={8}>
        <ListadoEntidadBancos />
      </Grid>
    </Grid>
  );
};

export default EntidadBancosView;
