import axios from "axios";
import { baseApi, token } from "../../services/Apis";

export const bancosApi = `${baseApi}/banks`;

export const getAllBancosService = async (params) => {
  const res = await axios.get(bancosApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveBancosService = async (id, data) => {
  if (data.logo) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.logo;
  }

  let res;
  if (id) {
    res = await axios.put(`${bancosApi}/${id}`, data, {
      headers: await token({
        isFormData: !!data.image,
      }),
    });
  } else {
    res = await axios.post(`${bancosApi}`, data, {
      headers: await token({
        isFormData: !!data.image,
      }),
    });
  }

  return res;
};
