import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const reservations = `${baseApi}/reservations`;

export const getAllReservasService = async (params) => {
  const res = await axios.get(reservations, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveReservasService = async (data) => {
  let res = await axios.post(`${reservations}`, data, {
    headers: await token({}),
  });
  return res;
};

export const getOneReservasService = async (id) => {
  const res = await axios.get(`${reservations}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteReservasService = async (id) => {
  const res = await axios.delete(`${reservations}/${id}`, {
    headers: await token({}),
  });
  return res;
};
