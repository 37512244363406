import { SU } from '../../constants/ConfigConst';
import { MANAGER } from '../../constants/FieldsConst';

export const checkModuleAccess = (module, loginStore) => {
  const isRoot = loginStore.roles.indexOf(SU) !== -1;
  if (isRoot) return true;
  const modules = loginStore.modules;
  const rename = {
    contact: 'Contacts',
    accessDoor: 'Control',
    document: 'Documents',
    payment: 'Payments',
    reservation: 'Reserves',
    invitation: 'Invitations',
    delivery: 'Delivery',
    user: 'Users',
    code: 'Codes',
    classes: 'Classes',
    event: 'Events',
  };
  
  const moduleAccess = modules?.find((m) => m.name === rename[module]);
  return moduleAccess && moduleAccess.active;
};

export const checkRootAccess = (loginStore) => {
  const isRoot = loginStore.roles.indexOf(SU) !== -1;
  if (!isRoot) {
    window.location.href = '/dashboard';
  }
};

export const isManagerUserCode = (userCodes, entityId) => {
  const userCode = userCodes.find(
    (userCode) => userCode.code.entity.id === entityId
  );
  return userCode && userCode.userType === MANAGER;
};
