import * as React from 'react';
import * as Redux from 'react-redux';
import MUIDataTable from 'mui-datatables';
import {
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Tooltip,
} from '@mui/material';
import {
  deleteReservasAction,
  setActivoReservasAction,
} from '../ReservasAction';
import ActionsList from '../../../components/ActionsList';
import * as CONST from '../../../constants/FieldsConst';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { Modal } from '../../../components';
import { dateformat, getDateFormated, getDateTimezone } from '../../../helpers';
import LoadingComponent from '../../../components/Loading';
import { reservations } from '../ReservasService';
import { useNotification } from '../../../helpers/notification';
import { downloadExcelFile } from '../../../helpers/exportHelper';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';

const ListadoReservas = ({ date, dateTo }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const areasStore = Redux.useSelector((state) => state.areas);
  const reservasStore = Redux.useSelector((state) => state.reservas);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const timezone = entidadesStore.activo.country.timezone;

  React.useEffect(() => {
    setRows([]);
    reservasStore.all.forEach((reserva) => {
      setRows((old) => [
        ...old,
        {
          [CONST.ID]: reserva.id,
          [CONST.AVATAR]: reserva.userCode.user.profile.avatar,
          [CONST.NAME]: `${reserva.userCode.user.profile.firstName} ${reserva.userCode.user.profile.lastName}`,
          [CONST.IDENTIFICATION_NUMBER]: `${reserva.userCode.user.profile.typeDocument}-${reserva.userCode.user.profile.identificationNumber}`,
          [CONST.CODE]: reserva.userCode.code.code,
          [CONST.AREA_ID]: reserva.subarea ? reserva.subarea.name : 'N/A',
          [CONST.DESCRIPTION]: reserva.description,
          [CONST.PRICE]: reserva.price,
          [CONST.DATE_FROM]: getDateFormated(
            reserva.entryTime,
            'DD-MM-YYYY',
            timezone
          ),
          [CONST.ENTRY_TIME]: getDateFormated(
            reserva.entryTime,
            'HH:mm a',
            timezone
          ),
          [CONST.DEPARTURE_TIME]: getDateFormated(
            reserva.departureTime,
            'HH:mm a',
            timezone
          ),
          requestOrigin: reserva.requestOrigin.charAt(0).toUpperCase() + reserva.requestOrigin.slice(1),
          createdById: reserva.createdBy,
        },
      ]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservasStore.all]);

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteReservasAction(
      { dispatch, addNotification },
      reservasStore.activo.id,
      {
        ...reservasStore.params,
        date: getDateTimezone(date, timezone),
        dateTo: getDateTimezone(dateTo, timezone),
      }
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: CONST.AVATAR,
      label: 'Foto',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Avatar alt={value} sx={{ width: 30, height: 30 }} src={value} />
          );
        },
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre y apellido',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <NameComponent>{value}</NameComponent>;
        },
      },
    },
    {
      name: CONST.IDENTIFICATION_NUMBER,
      label: 'Cédula',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <NameComponent>{value}</NameComponent>;
        },
      },
    },
    {
      name: CONST.CODE,
      label: 'Codigo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.AREA_ID,
      label: 'Sub área',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.DESCRIPTION,
      label: 'Motivo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <NameComponent>{value}</NameComponent>;
        },
      },
    },
    {
      name: CONST.PRICE,
      label: 'Precio',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <NameComponent>{value}</NameComponent>;
        },
      },
    },
    {
      name: CONST.DATE_FROM,
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.ENTRY_TIME,
      label: 'Hora de entrada',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.DEPARTURE_TIME,
      label: 'Hora de salida',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'requestOrigin',
      label: 'Origen',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let name = null;
          const reservation = reservasStore.all.find(el => el.id === tableMeta.rowData[0]);
          if (reservation?.createdBy) {
            if (reservation.createdBy.id === 1) name = 'Onpass';
            else {
              name = `${reservation.createdBy.user.profile.firstName} ${reservation.createdBy.user.profile.lastName}`;
            }
          }
          return <Tooltip title={name}>{value}</Tooltip>;
        }
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box align="left" sx={{ display: 'flex', flexDirection: 'row' }}>
                <ActionsList
                  id={tableMeta.rowData[0]}
                  onDelete={async () => {
                    const data = reservasStore.all.find(
                      (el) => el.id === tableMeta.rowData[0]
                    );
                    await setActivoReservasAction(dispatch, data);
                    setIsDelete(true);
                  }}
                />
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onDownload: () => {
      const url = `${reservations}/export`;
      const name = 'reservaciones';
      const params = {
        ...reservasStore.params,
        date: getDateTimezone(date, timezone),
        dateTo: getDateTimezone(dateTo, timezone),
        type: 'reservation',
        groupByBlockId: true,
      };
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={
          areasStore.loadingGetAll ||
          reservasStore.loadingGetAll ||
          loadingDelete
        }
        text={
          areasStore.loadingGetAll ? 'Cargando áreas...' : 'Cargando lista...'
        }
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {reservasStore.activo ? (
        <Modal
          id="modalDeleteReserva"
          title={`Eliminar bloque de reserva`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar esta reserva?
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '15px',
                }}
              >
                <div>
                  Motivo:&nbsp;&nbsp;&nbsp;
                  <strong>{reservasStore.activo.description}</strong>
                </div>
                <div>
                  Entrada:&nbsp;&nbsp;&nbsp;
                  <strong>
                    {dateformat(reservasStore.activo.entryTime, {
                      format: 'YYYY-MM-DD HH:mm',
                    })}
                  </strong>
                </div>
                <div>
                  Salida:&nbsp;&nbsp;&nbsp;
                  <strong>
                    {dateformat(reservasStore.activo.departureTime, {
                      format: 'YYYY-MM-DD HH:mm',
                    })}
                  </strong>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(reservasStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button onClick={() => setIsDelete(false)}>CANCELAR</Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoReservas;
