import { tryCatchHelper } from '../../helpers';
import {
  GETALL_RESERVAS_ESPECIALES,
  LOADING_GETALL_RESERVAS_ESPECIALES,
  ACTIVO_RESERVAS_ESPECIALES,
} from '../../store/Types';
import {
  deleteReservasService,
  getAllReservasService,
} from '../reservas/ReservasService';
import { saveReservasEspecialesService } from './ReservasEspecialesService';

export const getAllReservasEspecialesAction = async (
  dispatch,
  entityId,
  params
) => {
  try {
    dispatch({
      type: LOADING_GETALL_RESERVAS_ESPECIALES,
      payload: true,
    });
    const res = await getAllReservasService({
      entityId,
      type: 'block',
      groupByBlockId: true,
      ...params,
    });
    dispatch({
      type: GETALL_RESERVAS_ESPECIALES,
      payload: res.data.data,
    });
    dispatch({
      type: LOADING_GETALL_RESERVAS_ESPECIALES,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_RESERVAS_ESPECIALES,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const saveReservasEspecialesAction = async (
  { dispatch, addNotification },
  data,
  entityId,
  params
) => {
  try {
    let res = await saveReservasEspecialesService(data);
    addNotification(res.data.message ?? 'Reservas generadas.');
    await getAllReservasEspecialesAction(dispatch, entityId, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoReservasEspecialesAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_RESERVAS_ESPECIALES,
    payload: data,
  });
};

export const deleteReservasEspecialesAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  params
) => {
  try {
    const res = await deleteReservasService(id);
    addNotification(res.data.message ?? 'La reserva eliminado');
    await setActivoReservasEspecialesAction(dispatch, null);
    getAllReservasEspecialesAction(dispatch, entityId, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
