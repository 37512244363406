import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Modal } from '../../../../components';
import moment from 'moment';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
const diasString = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',
  holiday: 'Feriados',
};

const initSchedules = [];

function convertTo12HourFormat(time) {
  if (!time) return null;
  return moment(time, 'HH:mm').format('hh:mm A');
}

function convertTo24HourFormat(time) {
  if (!time) return null;
  return moment(time, 'hh:mm A').format('HH:mm');
}

export default function Schedules({ schedules = [], onChange = () => {} }) {
  const [rows, setRows] = React.useState(initSchedules);
  const [isAdd, setIsAdd] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);

  const filterDays = Object.keys(diasString)
    .filter((key) => {
      if (isEdit) return key === isEdit;
      return !schedules.find((schedule) => schedule.day === key);
    })
    .map((key) => {
      return {
        value: key,
        label: diasString[key],
      };
    });

  const checkTimes = (times, _min) => {
    const [_time1, _time2] = times;
    if (!_time1 || !_time2) return false;
    const time1 = new Date(`1970-01-01T${_time1}`);
    const time2 = new Date(`1970-01-01T${_time2}`);
    const min = new Date(`1970-01-01T${_min}`);

    if (time2 < time1) return false;
    if (time1 < min) return false;
    return true;
  };

  const handleSaveAdd = () => {
    const check =
      checkTimes(data.schedules[0]) &&
      (data.schedules[1]
        ? checkTimes(data.schedules[1], data.schedules[0][1])
        : true);

    if (!check) return;

    const dto = {
      day: data.day,
      schedules: data.schedules
        .filter((schedule) => schedule)
        .map((schedule) => schedule.join(' - ')),
    };

    let newSchedules = [];

    if (isEdit) {
      newSchedules = [...schedules];
      const index = newSchedules.findIndex(
        (schedule) => schedule.day === isEdit
      );
      newSchedules[index] = dto;
    } else {
      newSchedules = [...schedules, dto];
    }
    const sortedSchedules = newSchedules.sort((a, b) => {
      const days = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
        'holiday',
      ];
      return days.indexOf(a.day) - days.indexOf(b.day);
    });
    onChange(sortedSchedules);
    setIsAdd(false);
  };

  const handleOpenModalAdd = () => {
    const day = filterDays[0].value;
    const schedules = [
      ['08:00 AM', '12:00 PM'],
      ['02:00 PM', '06:00 PM'],
    ];

    if (['saturday', 'sunday', 'holiday'].includes(day)) {
      schedules.splice(1, 1);
    }
    setData({
      day,
      schedules,
    });
    setIsAdd(true);
  };

  const handleOpenModalEdit = (row) => {
    const data = schedules.find((schedule) => schedule.day === row.day);
    setData({
      day: data.day,
      schedules: data.schedules.map((schedule) => schedule.split(' - ')),
    });
    setIsAdd(true);
    setIsEdit(row.day);
  };

  React.useEffect(() => {
    setRows(schedules);
  }, [schedules]);

  React.useEffect(() => {
    if (!isAdd) {
      setData(null);
      setIsEdit(false);
    }
  }, [isAdd]);

  return (
    <Grid>
      <Typography variant="h6" gutterBottom component="div" align="center">
        Horarios
        {schedules.length < Object.keys(diasString).length && (
          <Button onClick={() => handleOpenModalAdd()} size="small">
            <AddIcon fontSize="small" />
          </Button>
        )}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Dia</TableCell>
              <TableCell align="right">Primer bloque</TableCell>
              <TableCell align="right">Segundo bloque</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {diasString[row.day]}
                </TableCell>
                <TableCell align="right">{row.schedules[0]}</TableCell>
                <TableCell align="right">{row.schedules[1]}</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => {
                      handleOpenModalEdit(row);
                    }}
                    size="small"
                  >
                    <EditIcon fontSize="small" />
                  </Button>
                  <Button
                    onClick={() => {
                      const newSchedules = [...schedules];
                      newSchedules.splice(index, 1);
                      onChange(newSchedules);
                    }}
                    size="small"
                  >
                    <RemoveIcon fontSize="small" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isAdd && (
        <Modal
          id="modalAddSchedule"
          title={isEdit ? 'Editar horario' : 'Agregar horario'}
          open={isAdd}
          maxWidth="sm"
          onClose={() => setIsAdd(!isAdd)}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth size="small">
                  <InputLabel
                    htmlFor="day"
                    style={{ background: '#fff', padding: '0 5px' }}
                  >
                    Día
                  </InputLabel>
                  <Select
                    id="day"
                    value={data.day}
                    disabled={isEdit}
                    onChange={(e) => setData({ ...data, day: e.target.value })}
                  >
                    {filterDays.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="time-range-picker"
                    style={{
                      background: '#fff',
                      padding: '0 5px',
                    }}
                  >
                    Primer bloque
                  </InputLabel>
                  <TimeRangePicker
                    id="time-range-picker"
                    onChange={(e) => {
                      const d = e
                        ? [
                            convertTo12HourFormat(e[0]),
                            convertTo12HourFormat(e[1]),
                          ]
                        : null;
                      setData({
                        ...data,
                        schedules: [d, data.schedules[1]],
                      });
                    }}
                    value={
                      data.schedules[0]
                        ? [
                            convertTo24HourFormat(data.schedules[0][0]),
                            convertTo24HourFormat(data.schedules[0][1]),
                          ]
                        : null
                    }
                    openClockOnFocus={false}
                    disableClock={true}
                    format="hh:mm a"
                    clearIcon={null}
                    minTime="00:00:00"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel
                    shrink
                    htmlFor="time-range-picker"
                    style={{
                      background: '#fff',
                      padding: '0 5px',
                    }}
                  >
                    Segundo bloque
                  </InputLabel>

                  <TimeRangePicker
                    onChange={(e) => {
                      const d = e
                        ? [
                            convertTo12HourFormat(e[0]),
                            convertTo12HourFormat(e[1]),
                          ]
                        : null;
                      setData({
                        ...data,
                        schedules: [data.schedules[0], d],
                      });
                    }}
                    value={
                      data.schedules[1]
                        ? [
                            convertTo24HourFormat(data.schedules[1][0]),
                            convertTo24HourFormat(data.schedules[1][1]),
                          ]
                        : null
                    }
                    openClockOnFocus={false}
                    disableClock={true}
                    format="hh:mm a"
                    minTime={
                      data.schedules[0]
                        ? convertTo24HourFormat(data.schedules[0][1])
                        : '00:00:00'
                    }
                    maxTime="23:59:59"
                    onInvalidChange={(e) => console.log(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleSaveAdd()}
            >
              GUARDAR
            </Button>
            <Button color="error" onClick={() => setIsAdd(false)}>
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      )}
    </Grid>
  );
}
