import * as React from 'react';
import * as Redux from 'react-redux';
import MUIDataTable from 'mui-datatables';
import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Tooltip,
} from '@mui/material';
import {
  deleteTiposInvitacionAction,
  getAllTiposInvitacionAction,
  saveTiposInvitacionAction,
  setActivoTiposInvitacionAction,
} from '../TiposInvitacionAction';
import ActionsList from '../../../components/ActionsList';
import * as CONST from '../../../constants/FieldsConst';
import { Modal } from '../../../components';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { CYAN_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import { dateformat } from '../../../helpers';
import LoadingComponent from '../../../components/Loading';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import { useNotification } from '../../../helpers/notification';

const ListadoTiposInvitacion = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposInvitacionStore = Redux.useSelector(
    (state) => state.tiposInvitacion
  );

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);

  React.useEffect(() => {
    if (tiposInvitacionStore.all && tiposInvitacionStore.all.length === 0) {
      getAllTiposInvitacionAction(dispatch, entidadesStore.activo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const fetchData = () => {
      const rows = [];
      tiposInvitacionStore.all.forEach((el) => {
        rows.push({
          ...el,
          [CONST.DATE]: dateformat(el.date, {}),
          active: el.active ? 'Activo' : 'Bloqueado',
          actions: el,
        });
      });
      setRows(rows);
    };
    fetchData();
  }, [tiposInvitacionStore.all]);

  const _handleEditClick = async (id) => {
    const data = tiposInvitacionStore.all.find((el) => el.id === id);
    await setActivoTiposInvitacionAction(dispatch, data);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteTiposInvitacionAction(
      { dispatch, addNotification },
      id,
      entidadesStore.activo.id
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveTiposInvitacionAction(
      { dispatch, addNotification },
      entidadesStore.activo.id,
      id,
      {
        active: !tiposInvitacionStore.activo.active,
      }
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.NAME,
      label: 'Documento',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => _handleEditClick(tableMeta.rowData[0])}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.MAX_INVITATIONS,
      label: 'Maximo de invitaciones',
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: () => {
          const label = 'Max. invitaciones';
          const fullLabel = 'Maximo de invitaciones';
          return (
            <Tooltip title={fullLabel}>
              <span>{label}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.DAYS_MAX_INVITATIONS,
      label: 'Cantidad de días para el máximo de invitaciones',
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: (columnMeta, handleToggleColumn) => {
          const label = 'Días máx. invitaciones';
          const fullLabel = 'Cantidad de días para el máximo de invitaciones';
          return (
            <Tooltip title={fullLabel}>
              <span>{label}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.MAX_INVITATIONS_PER_GUEST,
      label: 'Máximo de invitaciones por invitado',
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: () => {
          const label = 'Max. invitaciones por invitado';
          const fullLabel = 'Máximo de invitaciones por invitado';
          return (
            <Tooltip title={fullLabel}>
              <span>{label}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.DAYS_MAX_INVITATIONS_PER_GUEST,
      label: 'Cantidad de días para el máximo de invitaciones por invitado',
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => {
          const label = 'Días máx. invitaciones por invitado';
          const fullLabel =
            'Cantidad de días para el máximo de invitaciones por invitado';
          return (
            <Tooltip title={fullLabel}>
              <span>{label}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.ACTIVE,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value}
                size="small"
                sx={{
                  backgroundColor:
                    value === 'Activo' ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box align="left" sx={{ width: '5em' }}>
              <ActionsList
                id={tableMeta.rowData[0]}
                onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                onDelete={async () => {
                  await setActivoTiposInvitacionAction(dispatch, value);
                  setIsDelete(true);
                }}
                onToggleActive={async () => {
                  await setActivoTiposInvitacionAction(dispatch, value);
                  setIsToggleActive(true);
                }}
                active={value.active}
              />
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onSearchChange: (text) => {
      console.log('buscardor', text);
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={tiposInvitacionStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {tiposInvitacionStore.activo && (
        <Modal
          id="modalFormTiposInvitacion"
          title="Eliminar"
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este tipo de invitación?
              <p>
                <strong>{tiposInvitacionStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(tiposInvitacionStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      )}

      {tiposInvitacionStore.activo && (
        <Modal
          id="modalToggleActivo"
          title={`${
            tiposInvitacionStore.activo.active ? 'Bloquear' : 'Activar'
          } ${tiposInvitacionStore.activo.name}`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                tiposInvitacionStore.activo.active ? 'Bloquear' : 'Activar'
              } este tipo de invitación?`}
              <p>
                <strong>{tiposInvitacionStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(tiposInvitacionStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      )}
    </Grid>
  );
};

export default ListadoTiposInvitacion;
